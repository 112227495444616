<template>
  <div class="print-model">
    <div id="printMe" ref="printMe" v-if="!loading">
      <div class="design-1-container" v-if="options.print_type == 4">
        <div class="header">
          <img :src="logo" class="companyImage" v-if="options.image != null" />
          <qrcode-vue
            :value="item.qrCode"
            size="130"
            level="H"
            render-as="svg"
            v-if="$option.tax_invoice"
          />
          <div class="companydetails">
            <h1>{{ options.name }}</h1>
            <p v-if="options.telephone">الهاتف: {{ options.telephone }}</p>
            <p>{{ options.country }}</p>
            <p>
              {{ options.address1 }}
              {{ options.address1 && options.city ? "," : "" }}
              {{ options.city }}
            </p>
          </div>
        </div>
        <div class="info">
          <div class="invoive-detail">
            <div class="invoice-num">
              <strong>رقم الفاتورة<br />Invoice No</strong>
              <p>{{ item.code }}</p>
            </div>
            <div class="invoice-date">
              <strong>تاريخ الوصول<br />due Date</strong>
              <p>{{ $dateFormat(item.expiry_date) }}</p>
            </div>
          </div>
          <div class="cleint">
            <h2 class="cleint-name">العميل<br />Cleint</h2>
            <div class="cleint-info" v-if="item.client_type == 0">
              <strong>{{ item.customer.name }}</strong>
              <p>{{ item.customer.mobile }}</p>
              <p>{{ item.customer.address1 }}</p>
            </div>
            <div class="cleint-info" v-if="item.client_type == 1">
              <strong>{{ item.supplier.name }}</strong>
              <p>{{ item.supplier.mobile }}</p>
              <p>{{ item.supplier.address1 }}</p>
            </div>
          </div>
        </div>
        <div >
          <p><strong>تاريخ الفاتورة</strong>{{ $dateFormat(item.date) }}</p>
        </div>

        <div class="w-100" v-if="item.notes != null">
          <hr class="my-3" />
          <h4 class="text-uppercase">{{ $t("invoices.show.notes") }}</h4>
          <div class="mb-0 imageCont" v-html="item.notes"></div>
        </div>
        <div class="w-100" v-if="item.terms != null">
          <hr class="my-3" />
          <h4 class="text-uppercase">
            {{ $t("invoices.form.termsOfOffer") }}
          </h4>
          <div class="mb-0" v-html="item.terms"></div>
        </div>
        <div class="w-100" v-if="$option.invoice_footer != null">
          <hr class="my-3" />
          <div class="mb-0" v-html="$option.invoice_footer"></div>
        </div>


        



        <div style="margin-top: 0px;"></div>
        <template v-for="(qItem, index) in item.quotationItems">
        <div class="container-of-table">
          <table class="mytable">
            <thead>
              <td v-if="$option.item_number">#</td>
              <td>
                الصنف
                <br />
                Item
              </td>
              <td>
                الكمية
                <br />
                Qty
              </td>
              <td>
                السعر
                <br />
                Price
              </td>
              <td v-if="item.discount != 0 && $option.show_discount_table">
                قيمة الخصم
                <br />
                Discount Amount
              </td>
              <td v-if="item.tax != 0 && $option.show_tax_table">
                قيمة الضريبة
                <br />
                VAT Amount
              </td>
              <td>
                الأجمالي
                <br />
                Total
              </td>
            </thead>
            <tbody>
              
              <tr >
                <td v-if="$option.item_number">{{ index + 1 }}</td>
                <td>{{ qItem.product_name }}</td>
                <td>
                  {{ parseFloat(qItem.quantity).toFixed(2) }}
                </td>
                <td>{{ $moneyFormat(qItem.price) }}</td>
                <td v-if="item.discount != 0 && $option.show_discount_table">
                  -{{ $moneyFormat(qItem.discount) }}
                </td>
                <td v-if="item.tax != 0 && $option.show_tax_table">
                  {{ $moneyFormat(qItem.average) }}
                </td>
                <td>{{ $moneyFormat(qItem.total) }}</td>
              </tr>
              <tr>
                <td
                  :colspan="4"
                  class="td-parent imageCont"
                  v-html="qItem.description"
                ></td>
              </tr>
          </tbody>
          </table>
        </div>
      </template>
      <div class="money-output" style="width: 100%;">
          <div class="total">
            <strong
              >المجموع<br />
              Subtotal</strong
            >
            <p>
              {{ $moneyFormat(item.subtotal + item.discount) }}
            </p>
          </div>
          <div class="total" v-if="item.edit">
            <strong>
              {{
                item.edit_text
                  ? item.edit_text
                  : $t("quotations.form.Modification")
              }}
            </strong>
            <p>{{ $moneyFormat(item.edit) }}</p>
          </div>
          <div class="total" v-if="item.delivery">
            <strong
              >التوصيل<br />
              delivery</strong
            >
            <p>{{ $moneyFormat(item.delivery) }}</p>
          </div>
          <div class="total" v-if="item.discount">
            <strong
              >خصم<br />
              Discount</strong
            >
            <p>- {{ $moneyFormat(item.discount) }}</p>
          </div>
          <div class="total" v-if="item.tax">
            <strong
              >الضريبة<br />
              Tax</strong
            >
            <p>{{ $moneyFormat(item.tax) }}</p>
          </div>
          <div
            class="total"
            v-if="item.edit || item.delivery || item.discount || item.tax"
          >
            <strong>الأجمالي<br />Total</strong>
            <p>
              <strong>{{ $moneyFormat(item.total) }} </strong>
            </p>
          </div>
          <template v-if="$option.show_old_balance">
            <div class="total">
              <strong
                >الرصيد السابق<br />
                Previous Balance</strong
              >
              <p>
                {{
                  $moneyFormat(
                    $option.balance_type == 0
                      ? item.old_balance
                      : item.old_balance * -1
                  )
                }}
              </p>
            </div>
            <div class="total">
              <strong
                >الرصيد الكلي<br />
                Total Balance</strong
              >
              <p>
                {{
                  $moneyFormat(
                    $option.balance_type == 0
                      ? item.new_balance
                      : item.new_balance * -1
                  )
                }}
              </p>
            </div>
            <div class="total" v-if="item.paid">
              <strong>المدفوع<br />Paid</strong>
              <p>{{ $moneyFormat(item.paid) }}</p>
            </div>
            <div class="total" v-if="item.paid">
              <strong>الرصيد النهائي<br />final balance</strong>
              <p>
                {{
                  item.salesPayment
                    ? $moneyFormat(
                        $option.balance_type == 0
                          ? item.salesPayment.new_balance
                          : item.salesPayment.new_balance * -1
                      )
                    : $moneyFormat(
                        $option.balance_type == 0
                          ? item.new_balance
                          : item.new_balance * -1
                      )
                }}
              </p>
            </div>
          </template>
        </div>
        <p class="tafqeet-style">
          اجمالي الفاتورة فقط {{ $tafqeet(item.total) }}
        </p>
        <div class="signeture-container">
          <div
            class="signeture-item"
            v-for="(signeture, index) in options.orders_signatures_data"
            :key="index"
          >
            <div class="signeture-name">{{ signeture.name }}</div>
            <div class="signeture-points">..........................</div>
          </div>
        </div>
      </div>
      <p class="pioneer-data">
        تم الإنشاء بواسطة Pioneer للبرمجيات 01019654321
      </p>
    </div>
  </div>
</template>
<script>
import QrcodeVue from "qrcode.vue";
export default {
  data() {
    return {
      path: "/invoices",
      logo: localStorage.getItem("logo"),
      item: {},
      unitsCoefficients: [],
      loading: true,
      printType: true,
      itemsCount: 0,
    };
  },
  methods: {
    getitem() {
      this.loading = true;
      this.item = this.$parent.printedInvoice;
      let unitsCoefficients = [];
      this.item.quotationItems.forEach((item) => {
        this.itemsCount += item.quantity - 0;
        item.unit_coefficients.forEach((el) => {
          unitsCoefficients.push(el.id);
        });
      });
      unitsCoefficients = [...new Set(unitsCoefficients)];
      this.unitsCoefficients = this.$database.unitsCoefficients.filter((row) =>
        unitsCoefficients.includes(row.id)
      );
      this.unitsCoefficients = this.unitsCoefficients.sort(
        this.$dynamicSort({ orderBy: "id", orderType: "desc" })
      );
      if (this.$option.tax_invoice) {
        var sellerNameBuf = this.getTlvForValue("1", this.$option.name);

        var vatRegistrationNameBuf = this.getTlvForValue(
          "2",
          this.$option.tax_number
        );
        var timeBuf = this.getTlvForValue("3", this.item.created_at);
        var totalInvoiceBuf = this.getTlvForValue(
          "4",
          this.item.total.toFixed(2).toString()
        );
        var taxAmountBuf = this.getTlvForValue(
          "5",
          this.item.tax.toFixed(2).toString()
        );
        var tagBufsArray = [
          sellerNameBuf,
          vatRegistrationNameBuf,
          timeBuf,
          totalInvoiceBuf,
          taxAmountBuf,
        ];
        var qrCodeBuf = Buffer.concat(tagBufsArray);
        var qrCodeB64 = qrCodeBuf.toString("base64");
        this.item.qrCode = qrCodeB64;
      }
      this.loading = false;
      var myel = this;
      setTimeout(function () {
        var text = document.getElementById("printMe").innerHTML;
        document.getElementById("printMe").remove();
        var myframe = document.getElementById("inviceIframe");
        myframe.contentDocument.body.innerHTML = text;
        setTimeout(function () {
          myframe.focus();
          myframe.contentWindow.print();
          myel.colse();
        }, 1500);
      }, 20);
    },
    getTlvForValue(tagNum, tagValue) {
      var tagBuf = Buffer.from([tagNum], "utf8");

      var tagvalueBuf = Buffer.from(tagValue, "utf8");

      var tagValueLenBuf = Buffer.from([tagvalueBuf.length], "utf8");

      var bufsArray = [tagBuf, tagValueLenBuf, tagvalueBuf];

      return Buffer.concat(bufsArray);
    },
    colse() {
      this.$parent.printedInvoice = false;
    },
  },
  mounted() {
    this.getitem();
  },
  props: ["options"],
  components: {
    QrcodeVue,
  },
};
</script>
